import React from "react";
// import "./SubNav.sass";
import { Container, Grid, useMediaQuery, Button, CardMedia } from "@material-ui/core";
import banner from "../../../assets/banner_site.png";
import preview from "../../../assets/preview.jpg"
import TitlePage from "../../../components/Typography/TitlePage";
import Text from "../../../components/Typography/Text";
import { useStaticQuery, graphql } from "gatsby";

const SubNav = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulSubNav {
        nodes {
          childContentfulSubNavSubNavDescriptionRichTextNode {
            content {
              content {
                value
              }
            }
          }
        }
      }
    }
  `).allContentfulSubNav.nodes[0]
    .childContentfulSubNavSubNavDescriptionRichTextNode.content[0].content;
  const mobile = useMediaQuery("(min-width:600px)");
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container justify="center" spacing={3}>
          <Grid item>

            <CardMedia
              component="img"
              alt="logo"
              title="Mentor Me"
              image={preview}
            />

          </Grid>
        </Grid>
        <Grid container spacing={3} id="sub-nav-container-grid">

          <Grid item xs={mobile ? 8 : 12}>
            {/*<TitlePage variant={mobile ? "h2" : "h4"}>
              {"Primul program de mentorat din Moldova"}
            </TitlePage>
            <TitlePage variant={mobile ? "h2" : "h4"}>
              {"MentorMe celebrează 10 ani de performanță!"}
            </TitlePage>*/}
          </Grid>
          <Grid item xs={mobile ? 7 : 12}>
            {/*<Typography
                  style={{ backgroundColor: "transparent" }}
                  variant="h5"
                >
                  Conectăm și ajutăm <strong>gratuit</strong> tinerii să-și
                  urmeze studiile peste hotare, să-și accelereze dezvoltarea
                  profesională și să identifice noi oportunități de afaceri.
                </Typography>

            <Text>
              {data?.map((item, index) => (
                <div
                  dangerouslySetInnerHTML={{ __html: item.value }}
                  key={`${index}-key-inner-html`}
                ></div>
              ))}
            </Text>*/}
            {/* <Hidden smDown>
                <Grid item xs={6}>
                  <Card
                    elevation={0}
                    style={{
                      zIndex: 4,
                      backgroundColor: "transparent",
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt="logo"
                      title="Mentor Me"
                      image={head}
                    />
                  </Card>
                </Grid>
              </Hidden> */}
          </Grid>
          <Grid item xs={12}>
            <Button
              // fullWidth
              variant="contained"
              // color="secondary"
              //href="https://docs.google.com/forms/d/e/1FAIpQLSeHwJxV0bz7Rfqnyb8yJtrZU35ALzzJTZQzJvhpW5G6sKaEaQ/viewform?pli=1"
              href="https://forms.gle/4RXpNcz1tWVfA9wD8"
              size="large"
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                fontFamily: "Poppins",
                borderRadius: 12,
                backgroundColor: "#E83484",
                color: "#fff",
                marginRight: "56px",
                marginTop: '24px',
              }}
            >
              {"Înregistrare"}
            </Button>
            <Button
              variant="outlined"
              href="https://facebook.com/events/s/forum-mentorme-i-urmatorul-pas/1311517113631627/"
              //href="https://docs.google.com/forms/d/e/1FAIpQLSfsWRmZ_uMYsVWc9SsEmxT62qAxVxcqtg6DXGm0hssZSTYIGw/viewform?fbclid=PAZXh0bgNhZW0CMTEAAaYdIcJzCqCz4rE_qlGR5HMOYISk17BHFS8e4MMnNrVd7YkUKnaST1guJVg_aem_nkJpn7kXodNA884H0Q1LiA"
              size="large"
              color="secondary"
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                fontFamily: "Poppins",
                borderRadius: 12,
                marginTop: '24px',
              }}
            >
              {"mai multe detalii"}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default SubNav;
