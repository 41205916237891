import React from "react";

import Header from "../components/MainComponents/Header/Header";
import Footer from "../components/MainComponents/Footer/Footer";

const DefaultLayout = ({ children, location }) => {
  return (
    <div>
      <Header location={location} />
      <div style={{ marginTop: "160px" }}>{children}</div>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
