import React from "react";
import { Link } from "gatsby";
// import "normalize.css";
import "../../static/globalStyles/global.sass";
import { graphql } from "gatsby";
import DefaultLayout from "../layout/DefaultLayout";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Hidden,
  Breadcrumbs,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SubTitlePage from "../components/Typography/SubTitlePage";
import Text from "../components/Typography/Text";

const useStyles = makeStyles({
  div: {
    paddingTop: 32,
  },
});

function About({ location, data }) {
  const pathname = location.pathname;
  const nodes = data?.allContentfulDespreNoi.nodes;
  const text = data?.allContentfulDespreNoiText;

  const classes = useStyles();

  const getText = (i) => text.nodes[i];

  const mobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const cards_mobile = () =>
    nodes.map((node) => (
      <Grid item xs={4} key={node.id} className="about_images">
        <Card
          style={{ backgroundColor: "#fff", borderRadius: 32 }}
          elevation={1}
        >
          <CardMedia
            component="img"
            alt={`${node.name}`}
            title={`${node.name} - ${node.studii}`}
            image={`${node.avatar.fluid.src}`}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {`${node.name}`}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
            >{node.studii}</Typography>
          </CardContent>
        </Card>
      </Grid>
    ));

  const cards_desktop = () =>
    nodes.map((node) => (
      <Grid item xs={12} key={node.id}>
        <Card
          style={{ borderRadius: 32, backgroundColor: "#fff" }}
          elevation={1}
        >
          <CardMedia
            component="img"
            alt={`${node.name}`}
            title={`${node.name} - ${node.studii}`}
            image={`${node.avatar.fluid.src}`}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {`${node.name}`}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
            >{node.studii}</Typography>
          </CardContent>
        </Card>
      </Grid>
    ));

  return (
    <DefaultLayout location={pathname}>
      <Container maxWidth={md ? "lg" : "md"}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small"/>}
              aria-label="breadcrumb"
            >
              <Link
                to="/"
                style={{
                  fontFamily: "Poppins",
                  color: "#000",
                  fontStyle: "normal",
                  textDecoration: "none",
                  fontWeight: "normal",
                }}
              >
                {"Acasă"}
              </Link>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  color: "#000",
                }}
              >
                {"Despre noi"}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <SubTitlePage variant={mobile ? "h3" : "h4"}>
              {"Cine suntem?"}
            </SubTitlePage>
          </Grid>
          <Grid item xs={md ? 8 : 12}>
            <Text variant={mobile ? "h5" : "h6"}>
              {getText(0)?.bigText?.content[0]?.content?.map((item) => (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.value,
                  }}
                  key={getText(0).id}
                ></div>
              ))}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <SubTitlePage variant={mobile ? "h3" : "h4"}>
              {"Echipa MentorMe"}
            </SubTitlePage>
          </Grid>
          <Grid item xs={md ? 8 : 12}>
            <Text variant={mobile ? "h5" : "h6"}>
              {getText(1)?.bigText?.content[0]?.content?.map((item) => (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.value,
                  }}
                  key={getText(1).id}
                ></div>
              ))}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Hidden xsDown>
              {/**
               * MOBILE
               */}
              <Grid
                container
                spacing={3}
                direction="row"
                className={classes.div}
              >
                {cards_mobile()}
              </Grid>
            </Hidden>
            <Hidden smUp>
              {/**
               * DESKTOP
               */}
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
                className={classes.div}
              >
                {cards_desktop()}
              </Grid>
            </Hidden>
          </Grid>
          <Grid item xs={12}>
            <SubTitlePage variant={mobile ? "h3" : "h4"}>
              {"Fă parte din echipa MentorMe"}
            </SubTitlePage>
          </Grid>
          <Grid item xs={md ? 8 : 12}>
            <Text variant={mobile ? "h5" : "h6"}>
              {getText(2)?.bigText?.content[0]?.content?.map((item) => (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.value,
                  }}
                  key={getText(2).id}
                ></div>
              ))}
            </Text>
          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  );
}

export default About;

export const query = graphql`
  {
    allContentfulDespreNoi(sort: { fields: order }) {
      nodes {
        id
        avatar {
          fluid(quality: 100) {
            src
          }
        }
        bgColor
        name
        location
        studii
        id
      }
    }
    allContentfulDespreNoiText(sort: { fields: createdAt }) {
      nodes {
        id
        bigText {
          content {
            content {
              value
            }
          }
        }
        id
      }
    }
  }
`;
