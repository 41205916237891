import React, { useState } from "react";
// import "normalize.css";
import "../../static/globalStyles/global.sass";
import { Link } from "gatsby";
import DefaultLayout from "../layout/DefaultLayout";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import {
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Grid,
  createMuiTheme,
  ThemeProvider,
  Breadcrumbs,
  useMediaQuery,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { graphql } from "gatsby";
import TitlePage from "../components/Typography/TitlePage";
import Text from "../components/Typography/Text";

const themeAccordion = createMuiTheme({
  overrides: {
    MuiAccordion: {
      root: {
        backgroundColor: "#1DA1A6",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  title: {
    color: "#fff",
    fontFamily: "Poppins",
  },
  description: {
    color: "#fff",
    paddingLeft: theme.spacing(2),
    fontFamily: "Poppins",
  },
  icon: {
    color: "#fff",
  },
}));

function SingleAccordion({ state }) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const classes = useStyles();

  return (
    <ThemeProvider theme={themeAccordion}>
      <Accordion
        expanded={expanded === state.panel}
        onChange={handleChange(state.panel)}
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon className={classes.icon} />
            ) : (
              <AddIcon className={classes.icon} />
            )
          }
          id={state.panel}
        >
          <Typography
            className={classes.title}
            variant={state.mobile ? "h6" : "body1"}
          >
            {state.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            className={classes.description}
            variant={state.mobile ? "body1" : "body2"}
          >
            {String(state.description).trim().normalize()}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </ThemeProvider>
  );
}

function Faq(props) {
  const pathname = props.location.pathname;
  const data = props.data.allContentfulFaq.nodes;

  const mobile = useMediaQuery("(min-width:600px)");
  return (
    <DefaultLayout location={pathname}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link to="/" className="heading_route">
                {"Acasă"}
              </Link>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  color: "#000",
                }}
              >
                {"FAQ"}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} style={mobile ? { textAlign: "center" } : {}}>
            <TitlePage variant={mobile ? "h3" : "h4"}>{"F.A.Q."}</TitlePage>
          </Grid>
          <Grid item xs={12} style={mobile ? { textAlign: "center" } : {}}>
            <Text variant={mobile ? "h6" : "body1"}>
              Aici vei găsi o listă cu cele mai frecvente întrebări și
              răspunsurile acestora.
            </Text>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ paddingBottom: 32, paddingTop: 32 }}
        >
          {data?.map((item) => (
            <Grid item xs={12} key={item.id}>
              <SingleAccordion
                state={{
                  panel: item.key,
                  title: item.title,
                  description:
                    item.childContentfulFaqStaticContentRichTextNode.content[0]
                      .content[0].value,
                  mobile: mobile,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </DefaultLayout>
  );
}

export default Faq;

export const query = graphql`
  {
    allContentfulFaq(sort: { order: ASC, fields: createdAt }) {
      nodes {
        id
        title
        key
        childContentfulFaqStaticContentRichTextNode {
          content {
            content {
              value
            }
          }
        }
        id
      }
    }
  }
`;
