import React, { useState } from "react";
import { Link } from "gatsby";
import {
  AppBar,
  Toolbar,
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  Card,
  CardMedia,
  Typography,
  Grid,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Hidden,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import "./Header.sass";
// import logo from "../../../assets/mentorme_logo.png";
import logo from "../../../../static/images/logo_v1.png";
import CloseIcon from "@material-ui/icons/Close";
// import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import PropTypes from "prop-types";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  media: {
    height: "74px",
  },
  root: {
    maxWidth: "83px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    // minHeight: 128,
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
  },
  text: {
    fontFamily: "Poppins",
    color: "#000",
    fontWeight: "bold",
    "&:hover": {
      color: "#1DA1A6",
    },
  },
  paper: {
    backgroundColor: "#F2E154",
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#fff",
      },
    },
    /*MuiPaper: {
      root: {
        backgroundColor: "#fafafa",
      },
    },*/
    MuiDrawer: {
      paperAnchorRight: {
        backgroundColor: "#F2E154",
      },
    },
    MuiButton: {
      outlinedSecondary: {
        color: "#E83484",
        border: `1px solid rgb(232, 52, 132,0.5)`,
        "&:hover": {
          border: `1px solid rgb(232, 52, 132,1.0)`,
          backgroundColor: `1px solid rgb(232, 52, 132,0.8)`,
        },
      },
    },
  },
});

function Header(props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { location } = props;

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  // const mobile = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <List>
          <ListItem style={{ alignContent: "end" }}>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon fontSize="large" />
            </IconButton>
            <Button
              variant="outlined"
              color="secondary"
              // size={mobile ? "large" : "small"}
              size="large"
              href="https://learninghub.mentorme.md/login/redirect"
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                fontFamily: "Poppins",
                borderRadius: 12,
              }}
            >
              {"Join Us"}
            </Button>
          </ListItem>
          {[
            {
              label: "Acasă",
              key: "home",
              link: "/",
            },
            {
              label: "Despre noi",
              key: "about",
              link: "/about",
            },
            {
              label: "Blog",
              key: "blog",
              link: "/blog",
            },
            {
              label: "FAQ",
              key: "faq",
              link: "/faq",
            },
            {
              label: "Contact",
              key: "contacts",
              link: "/contacts",
            },
            {
              label: "Learning Hub",
              key: "learningHub",
              link: "https://learninghub.mentorme.md/",
            },
          ].map((item) => {
            if(item.key === 'learningHub'){
              return (
                <ListItem key={item.key}>
                  <ListItemText
                    style={{ textAlign: "right", fontFamily: "Poppins" }}
                  >
                  <a
                    className={
                    location === item.link
                      ? "header-nav-list-item-link underlineDrawer"
                      : "header-nav-list-item-drawer"
                  }
                  href={item.link}
                  >
                  {item.label}
                  </a>
                </ListItemText>
              </ListItem>
              )
            }
            return (
            <ListItem key={item.key}>
              <ListItemText
                style={{ textAlign: "right", fontFamily: "Poppins" }}
              >
                <Link
                  className={
                    location === item.link
                      ? "header-nav-list-item-link underlineDrawer"
                      : "header-nav-list-item-drawer"
                  }
                  to={item.link}
                >
                  {item.label}
                </Link>
              </ListItemText>
            </ListItem>
          )})}
        </List>
      </Drawer>
      {/* <CssBaseline /> */}
      <ElevationScroll {...props}>
        <AppBar elevation={0}>
          <Toolbar className={classes.toolbar}>
            <Container maxWidth="lg">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                // spacing={3}
              >
                <Grid item xs={2}>
                  <Card className={classes.root} elevation={0}>
                    {/* <CardActionArea> */}
                    <CardMedia
                      component="img"
                      alt="logo"
                      title="Mentor Me"
                      // height="74px"
                      // style={{ height: "74px" }}
                      // className={classes.media}
                      image={logo}
                    />
                    {/* </CardActionArea> */}
                  </Card>
                </Grid>

                <Grid item xs>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={3}
                  >
                    <Hidden smDown>
                      <Grid item>
                        <Typography className={classes.text}>
                          <Link
                            to="/"
                            className={
                              location === "/"
                                ? "header-nav-list-item-link underline"
                                : "header-nav-list-item-link"
                            }
                          >
                            {"Acasă"}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.text}>
                          <Link
                            to="/about"
                            className={
                              location === "/about"
                                ? "header-nav-list-item-link underline"
                                : "header-nav-list-item-link"
                            }
                          >
                            {"Despre noi"}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.text}>
                          <Link
                            to="/blog"
                            className={
                              location === "/blog"
                                ? "header-nav-list-item-link underline"
                                : "header-nav-list-item-link"
                            }
                          >
                            {"Blog"}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.text}>
                          <Link
                            to="/faq"
                            className={
                              location === "/faq"
                                ? "header-nav-list-item-link underline"
                                : "header-nav-list-item-link"
                            }
                          >
                            {"FAQ"}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.text}>
                          <Link
                            to="/contacts"
                            className={
                              location === "/contacts"
                                ? "header-nav-list-item-link underline"
                                : "header-nav-list-item-link"
                            }
                          >
                            {"Contact"}
                          </Link>
                          </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.text}>
                          <a
                            href="https://learninghub.mentorme.md"
                            className={ "header-nav-list-item-link" }
                          >
                            {"Learning Hub"}
                          </a>
                        </Typography>
                      </Grid>
                    </Hidden>
                    <Hidden mdUp>
                      <Grid item style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <IconButton
                          onClick={toggleDrawer(true)}
                          style={{ padding: 0 }}
                        >
                          <MenuIcon fontSize="large" />
                        </IconButton>
                      </Grid>
                    </Hidden>
                    <Grid item style={{ paddingRight: 0 }}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        // size={mobile ? "large" : "small"}
                        size="large"
                        href="https://learninghub.mentorme.md/login/redirect"
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "bold",
                          fontFamily: "Poppins",
                          borderRadius: 12,
                        }}
                      >
                        {"Join Us"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </ThemeProvider>
  );
}

export default Header;
