import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import logo from "../../../assets/mentorme_logo.png";
import {
  Container,
  Card,
  CardMedia,
  CardHeader,
  CardContent,
  Typography,
  Collapse,
  IconButton,
  Grid,
  Avatar,
  useMediaQuery,
  // CardActionArea,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CardActions from "@material-ui/core/CardActions";
import "swiper/swiper-bundle.css";
import "./styles.css";
import SubTitlePage from "../../../components/Typography/SubTitlePage";
// import SwiperCore, { Navigation, Pagination } from "swiper";

// SwiperCore.use([Navigation, Pagination]);

function MySwiperSlide(props) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let { item, idx } = props;

  return (
    <SwiperSlide tag="li" style={{ listStyle: "none" }} virtualIndex={idx}>
      <Card elevation={3} style={{ borderRadius: 12 }}>
        <CardHeader
          avatar={<Avatar variant="square" src={logo} alt="Mentor Me" />}
          subheader={
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              style={{ fontFamily: "Poppins" }}
            >
              {new Date(`${item.publishedAt}`).toDateString()}
            </Typography>
          }
          title={
            <Typography
              variant="body1"
              style={{ fontFamily: "Poppins" }}
            >{`${item.channelTitle}`}</Typography>
          }
        />
        <a
          href={`https://www.youtube.com/watch?v=${item.videoId}`}
          target="_blank"
          rel="noreferrer"
        >
          <CardMedia
            image={`${item.thumbnail.url}`}
            title=""
            component="img"
            alt={"img"}
            width={`${(item.thumbnail.width / item.thumbnail.height) * 380}px`}
            height={`215px`}
          />
        </a>
        <CardContent>
          <Typography
            variant="body1"
            noWrap
            style={{ fontFamily: "Poppins" }}
          >{`${item.title}`}</Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton onClick={handleExpandClick}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography
              variant="body2"
              style={{ fontFamily: "Poppins" }}
            >{`${item.description}`}</Typography>
          </CardContent>
        </Collapse>
      </Card>
    </SwiperSlide>
  );
}

function Carousel() {
  const data = useStaticQuery(graphql`
    {
      allYoutubeVideo {
        nodes {
          id
          channelTitle
          description
          title
          thumbnail {
            url
            width
            height
          }
          videoId
          publishedAt
          id
        }
      }
    }
  `).allYoutubeVideo.nodes;
  const mobile = useMediaQuery("(min-width:600px)");

  const slides = data.map((item, i) => (
    <MySwiperSlide item={item} idx={i} key={item.id} />
  ));

  return (
    <Container maxWidth="lg" style={{ paddingTop: 32 }}>
      <Grid item xs={12}>
        <SubTitlePage variant={mobile ? "h3" : "h4"}>
          Ultimele interviuri
        </SubTitlePage>
      </Grid>
      <Grid item xs={12}>
        <Swiper
          id="youtube-videos"
          // thumbs={{ swiper: thumbsSwiper }}
          // controller={{ control: controlledSwiper }}
          tag="section"
          wrapperTag="ul"
          // navigation
          // pagination
          spaceBetween={3}
          slidesPerView={mobile ? 3.2 : 1.2}
        // onInit={(swiper) => console.debug(":> init\n", swiper)}
        // onSlideChange={(swiper) =>
        //   console.debug(":> change\n", swiper.activeIndex)
        // }
        // onReachEnd={(swiper) => console.debug(":> end\n")}
        >
          <ul
            className="swiper-wrapper"
          // style={{transform: {translate3d: "0,0,0", transitionDuration: "0ms"}}}
          // style="transform: translate3d(0px, 0px, 0px)"  transform: translate3d(0px, 0px, 0px); transition-duration: 0ms;
          >
            {slides}
          </ul>
        </Swiper>
      </Grid>
    </Container>
  );
}

export default Carousel;
