import React from "react";
// import { makeStyles, Typography } from "@material-ui/core";
// import SubTitlePage from "./TitlePage";
import TitlePage from "./TitlePage";
// const useStyles = makeStyles((theme) => ({
//   title: {
//     fontFamily: "Poppins",
//     color: "#002E5D",
//     fontWeight: "bold",
//     marginTop: theme.spacing(4),
//     marginBottom: theme.spacing(4),
//   },
// }));
export default (props) => {
    // const classes = useStyles();
  const { children, ...other } = props;
  return (
    // <Typography variant="h3" {...other} className={classes.title}>
    //   {children}
    // </Typography>
  <TitlePage variant="h3" {...other}>{children}</TitlePage>
  );
};
