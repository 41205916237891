import React, { Fragment, useState } from "react";
// import "normalize.css";
import "../../static/globalStyles/global.sass";
import { RecoilRoot } from "recoil";

import DefaultLayout from "../layout/DefaultLayout";
import { graphql } from "gatsby";
import SubNav from "../components/indexPageComponents/SubNav/SubNav";
import Carousel from "../components/indexPageComponents/Carousel/Carousel";
import { Helmet } from "react-helmet";
import {
  Container,
  Grid,
  Typography,
  CardMedia,
  Paper,
  Hidden,
  Card,
  useMediaQuery,
  CardActionArea,
} from "@material-ui/core";
import SubTitlePage from "../components/Typography/SubTitlePage";
import Text from "../components/Typography/Text";
import _11planning from "../../static/threeButtons/11planning.png";
import _12jobsearch from "../../static/threeButtons/12jobsearch.png";
import _13cooperation from "../../static/threeButtons/13cooperation.png";
import _21development from "../../static/threeButtons/21development.png";
import _22bestPractices from "../../static/threeButtons/22best_practices.png";
import _31graphic from "../../static/threeButtons/31graphic.png";
import _32choice from "../../static/threeButtons/32choice.png";
import _32network from "../../static/threeButtons/32network.png";
import _33chat from "../../static/threeButtons/33chat.png";

function Description(props) {
  const { text } = props;
  return (
    <React.Fragment>
      <Hidden smDown>
        <Grid item xs={7}>
          {/* <HighLight>{text}</HighLight> */}
          <Text>{text}</Text>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12}>
          {/* <HighLight variant="h5">{text}</HighLight> */}
          <Text>{text}</Text>
        </Grid>
      </Hidden>
    </React.Fragment>
  );
}

/*function TransitionGrow(props) {
  let { image } = props;
  const [state, setState] = useState(false);
  useEffect(() => {
    setState(true);
    return () => setState(false);
  }, [state]);
  return (
    <Grow in={state} {...(state ? { timeout: 1000 } : {})}>
      
    </Grow>
  );
}*/

function InfoSection(props) {
  const [state, setState] = useState({
    idx: 0,
  });
  const { mobile } = props;
  return (
    <Container maxWidth={"lg"} style={{ paddingTop: 32, paddingBottom: 32 }}>

      <Grid item xs={12}>
        <SubTitlePage variant={mobile ? "h3" : "h4"}>
          {props?.title}
        </SubTitlePage>
      </Grid>

      <Container maxWidth="md">



        {
          props.state?.map((item, i) => {
            if (i === 1 && mobile) {
              return (
                <Grid container direction="row" spacing={3} justify="center" alignItems="center">
                  {i === 0 && <Grid item xs={12}>
                    <Text variant={"body1"}>{item.header}</Text>
                  </Grid>}

                  <Description text={item.text} />

                  <Grid item style={{ alignContent: "center" }} xs={4}>
                    <Card elevation={0}
                      style={{
                        display: "inline-block",
                        backgroundColor: "transparent",
                      }}>
                      <CardMedia
                        component="img"
                        alt="logo"
                        title="Mentor Me"
                        //height={250}
                        // style={{maxHeight:"300px", maxWidth:"300px"}}
                        image={item.image}
                      />
                    </Card>
                  </Grid>


                </Grid>
              )
            } else {

              return (
                <Grid container direction="row" spacing={3} justify="center" alignItems="center">

                  {i === 0 && <Grid item xs={12}>
                    <Text variant={"body1"}>{item.header}</Text>
                  </Grid>}

                  <Grid item style={{ alignContent: "center" }} xs={4}>
                    <Card elevation={0}
                      style={{
                        display: "inline-block",
                        backgroundColor: "transparent",
                      }}>
                      <CardMedia
                        component="img"
                        alt="logo"
                        title="Mentor Me"
                        //height={250}
                        // style={{maxHeight:"300px", maxWidth:"300px"}}
                        image={item.image}
                      />
                    </Card>
                  </Grid>

                  <Description text={item.text} />
                </Grid>
              )

            }
          })
        }



      </Container>
    </Container>
  );
}

function PoppinsTypography({ children, variant, className }) {
  return (
    <Typography
      className={className}
      variant={variant}
      style={{ fontFamily: "Poppins", fontWeight: "bold" }}
    >
      {children}
    </Typography>
  );
}

function Statistics() {
  // const classes = useStyles();

  /**
   * TODO: replace with cards
   */
  /*const cards = [
    {
      title: "",
      subtitle: "",
      id: "",
    },
  ].map((item, i) => (
    <Grid item xs={4} key={`${i}-${item.id}`}>
      <Paper
        elevation={0}
        style={{
          backgroundColor: "#F2E154",
          color: "white",
          borderRadius: 16,
          minHeight: "168px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <PoppinsTypography variant="h2">{`${item.title}`}</PoppinsTypography>
        <PoppinsTypography>{`${item.subtitle}`}</PoppinsTypography>
      </Paper>
    </Grid>
  ));*/

  return (
    <Container maxWidth="lg" style={{ paddingTop: 32 }}>
      <Grid container spacing={3}>
        <Hidden smDown>
          <Grid item xs={4}>
            <Paper
              elevation={0}
              style={{
                backgroundColor: "#1DA1A6",
                color: "white",
                borderRadius: 16,
                minHeight: "168px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <PoppinsTypography variant="h2" className={"white"}>
                {"100+"}
              </PoppinsTypography>
              <PoppinsTypography>{"mentori"}</PoppinsTypography>
            </Paper>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Paper
              elevation={0}
              style={{
                backgroundColor: "#E83484",
                color: "white",
                borderRadius: 16,
                minHeight: "168px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <PoppinsTypography variant="h2" className={"white"}>
                {"950+"}
              </PoppinsTypography>
              <PoppinsTypography>{"conexiuni"}</PoppinsTypography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              elevation={0}
              style={{
                borderRadius: 16,
                minHeight: "168px",
                backgroundColor: "transparent",
              }}
            >
              <PoppinsTypography variant="h4">
                {"Câteva statistici ale programului nostru de mentorat"}
              </PoppinsTypography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              elevation={0}
              style={{
                backgroundColor: "#002E5D",
                color: "white",
                borderRadius: 16,
                minHeight: "168px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <PoppinsTypography variant="h2" className={"white"}>
                {"24+"}
              </PoppinsTypography>
              <PoppinsTypography>{"țări"}</PoppinsTypography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              elevation={0}
              style={{
                backgroundColor: "#F2E154",
                color: "white",
                borderRadius: 16,
                minHeight: "168px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <PoppinsTypography variant="h2" className={"white"}>
                {"500+"}
              </PoppinsTypography>
              <PoppinsTypography>{"utilizatori Learning Hub"}</PoppinsTypography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              elevation={0}
              style={{
                backgroundColor: "#002E5D",
                color: "white",
                borderRadius: 16,
                minHeight: "168px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <PoppinsTypography variant="h2" className={"white"}>
                {"100+"}
              </PoppinsTypography>
              <PoppinsTypography>{"evenimente"}</PoppinsTypography>
            </Paper>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={6}>
            <Paper
              elevation={0}
              style={{
                borderRadius: 16,
                minHeight: "168px",
                backgroundColor: "transparent",
              }}
            >
              <PoppinsTypography variant="h5">
                {"Câteva statistici ale programului nostru de mentorat"}
              </PoppinsTypography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              elevation={0}
              style={{
                backgroundColor: "#002E5D",
                color: "white",
                borderRadius: 16,
                minHeight: "168px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <PoppinsTypography variant="h3" className={"white"}>
                {"100+"}
              </PoppinsTypography>
              <PoppinsTypography>{"evenimente"}</PoppinsTypography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              elevation={0}
              style={{
                backgroundColor: "#1DA1A6",
                color: "white",
                borderRadius: 16,
                minHeight: "168px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <PoppinsTypography variant="h3" className={"white"}>
                {"100+"}
              </PoppinsTypography>
              <PoppinsTypography>{"mentori"}</PoppinsTypography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              elevation={0}
              style={{
                backgroundColor: "#E83484",
                color: "white",
                borderRadius: 16,
                minHeight: "168px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <PoppinsTypography variant="h3" className={"white"}>
                {"700+"}
              </PoppinsTypography>
              <PoppinsTypography>{"conexiuni"}</PoppinsTypography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              elevation={0}
              style={{
                backgroundColor: "#002E5D",
                color: "white",
                borderRadius: 16,
                minHeight: "168px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <PoppinsTypography variant="h3" className={"white"}>
                {"24+"}
              </PoppinsTypography>
              <PoppinsTypography>{"țări"}</PoppinsTypography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              elevation={0}
              style={{
                backgroundColor: "#F2E154",
                // textAlign: "center",
                color: "white",
                borderRadius: 16,
                minHeight: "168px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <PoppinsTypography variant="h3" className={"white"}>
                {"500+"}
              </PoppinsTypography>
              <PoppinsTypography>{"utilizatori"}</PoppinsTypography>
              <PoppinsTypography>{"Learning Hub"}</PoppinsTypography>
            </Paper>
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
}

function Parteners({ mobile, data }) {
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="baseline"
          spacing={3}
        >
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 32 }}>
            <SubTitlePage variant={mobile ? "h3" : "h4"}>
              {"Parteneri"}
            </SubTitlePage>
          </Grid>
          {data?.partnerImg?.map((e) => (
            <Grid item key={e.id}>
              <Card elevation={0}>
                <CardMedia
                  component="img"
                  alt={e.description}
                  title={e.title}
                  src={e.fluid.src}
                  style={{ width: "auto" }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

const MainPage = ({ location, data }) => {
  const pathname = location.pathname;

  const mobile = useMediaQuery("(min-width:600px)");

  let aboutProgram = [
    {
      text: "Planuri pentru studii sau carieră",
      header:
        "Programul de mentorat constă în discuții, tête-à-tête sau virtuale, dintre un mentor și un mentee, în care pot fi abordate următoarele subiecte:",
      image: _11planning,
      key: "0",
    },
    {
      image: _21development,
      text:
        "Oportunități pentru dezvoltare personală, experiența și lecțiile învățate de mentori",

      header:
        "Programul de mentorat constă în discuții, tête-à-tête sau virtuale, dintre un mentor și un mentee, în care pot fi abordate următoarele subiecte: ",
      key: "1",
    },
    {
      image: _31graphic,
      text:
        "Acces la platforma de cursuri online create de experți din diverse industrii",
      header:
        "Programul de mentorat constă în discuții, tête-à-tête sau virtuale, dintre un mentor și un mentee, în care pot fi abordate următoarele subiecte:",
      key: "2",
    },
  ];

  let benefits = [
    {
      text:
        "Vei cunoaște informații și sfaturi din prima sursă despre oportunități de studii și lucru peste hotare",
      header: "",
      image: _12jobsearch,
      key: "0",
    },
    {
      text:
        "Vei afla despre cele mai bune practici pentru îmbunătățirea abilităților tale și cum poți să-ți accelerezi dezvoltarea carierei",
      header: "",
      image: _22bestPractices,
      key: "1",
    },
    {
      text:
        "Vei putea să te conectezi cu antreprenori din Moldova și peste hotare pentru a identifica noi oportunități de afaceri și pentru a-ți extinde afacerea peste hotare",
      header: "",
      image: _32network,
      key: "2",
    },
  ];

  let howWorks = [
    {
      text: "Te alături grupului nostru de Facebook \
          MentorMe - Program de mentorat global  accesând butonul Join\
          Us sau direct de pe Facebook",
      header: "",
      image: _13cooperation,
      key: "0",
    },
    {
      text:
        "Găsești mentorul/mentee-ul potrivit după domeniu/ industrie/ țară în secțiunea ‘Mentorship’",
      header: "",
      image: _32choice,
      key: "1",
    },
    {
      text:
        "Te conectezi cu mentorul/mentee-ul și începi conversația de mentorat",
      header: "",
      image: _33chat,
      key: "2",
    },
  ];

  return (
    <div className="container">
      <RecoilRoot>
        <Helmet
          title={"MentorMe.md"}
          titleTemplate={`%s | mentorme.md`}
          meta={[
            {
              name: "description",
              content:
                "Conectăm și ajutăm gratuit tinerii să-și urmeze studiile universitare, să-și accelereze dezvoltarea profesională și să identifice noi oportunități de afaceri.",
            },
            {
              property: "og:title",
              content: "MentorMe - Primul program de mentorat din Moldova",
            },
            {
              property: "og:description",
              content:
                "Conectăm și ajutăm gratuit tinerii să-și urmeze studiile universitare, să-și accelereze dezvoltarea profesională și să identifice noi oportunități de afaceri.",
            },
            {
              property: "og:type",
              content: "website",
            },
            {
              property: `og:url`,
              content: `https://mentorme.md/`,
            },
            {
              property: `og:image`,
              content: "https:" + data.allContentfulAsset.nodes[0].file.url,
            },
          ]}
        />
        <DefaultLayout location={pathname}>
          <SubNav />

          <InfoSection
            state={aboutProgram}
            title="Ce oferă platforma MentorMe?"
            mobile={mobile}
          />
          <InfoSection
            state={benefits}
            title="Ce beneficii îți poate aduce?"
            mobile={mobile}
          />
          <InfoSection
            state={howWorks}
            title="Cum funcționează programul?"
            mobile={mobile}
          />

          <Statistics mobile={mobile} />
          {/* <MediaList /> */}
          <Carousel />
          <Parteners mobile={mobile} data={data.contentfulPartners} />
        </DefaultLayout>
      </RecoilRoot>
    </div>
  );
};

export default MainPage;

export const query = graphql`
  query MainPageQuery {

    allContentfulAsset(filter: {title: { eq: "MentorMe Logo"}}) {
      nodes {
        file {
          url
        }
      }
    }

    contentfulPartners {
      partnerImg {
        id
        fluid(maxWidth: 400, maxHeight: 250) {    
          src
        }
        title
        description
      }
    }
    allContentfulStatistics {
      nodes {
        bgColor
        subtitle
        title
      }
    }
    allContentfulThreeButtons(sort: { fields: createdAt, order: ASC }) {
      nodes {
        photos {
          fluid(quality: 100) {
            srcWebp
          }
        }
        descriptionText
        title
        componentDescription {
          content {
            content {
              value
            }
          }
        }
        objectId
      }
    }
  }
`;
