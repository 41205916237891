import React from "react";
import DefaultLayout from "../layout/DefaultLayout";
const Error404 = () => {
  return (
    <DefaultLayout>
      <div className="content" style={{ height: "35vh" }}>
        <h1 className="title">Page not found</h1>
        <p style={{ fontSize: "1.5rem" }}>
          Ooops! The page you are looking for doesn't exist :(
        </p>
      </div>
    </DefaultLayout>
  );
};

export default Error404;
