import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
}));

export default function StickyFooter() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Container component="main" className={classes.main} maxWidth="sm">
                <Typography variant="h2" component="h1" gutterBottom style={{ fontFamily: "Poppins" }}>
                    Mulțumim
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom tyle={{ fontFamily: "Poppins" }}>

                    {'Iți mulțumim pentru contact, revenim în curând.'}
                </Typography>
                <Typography variant="body1" tyle={{ fontFamily: "Poppins" }}>Echipa MentorMe</Typography>
            </Container>
        </div>
    );
}