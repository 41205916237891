import React from "react";
import DefaultLayout from "../layout/DefaultLayout";
import {
  Card,
  CardHeader,
  Avatar,
  Container,
  Grid,
  IconButton,
  Typography,
  Breadcrumbs,
  CardMedia,
  CardActionArea,
  CardContent,
  useMediaQuery,
} from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { graphql, Link } from "gatsby";
import { NavigateNext as NextIcon } from "@material-ui/icons/";
import "../../static/globalStyles/global.sass";
import "../../src/styles/global.sass";
import { Helmet } from "react-helmet";
import { RecoilRoot } from "recoil";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    backgroundColor: "#F2E154",
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  shareIcon: {
    backgroundColor: "#fff",
    color: "#002E5D",
  },
  wrapper: {
    fontFamily: "Poppins",
  },
  publishDate: {
    color: `rgba(0, 0, 0, 0.5)`,
  },
}));

function Item({ item }) {
  return (
    <Card elevation={1} style={{ borderRadius: 12 }}>
      <CardActionArea href={`/blog/${item.slug}`}>
        <CardMedia
          src={item.heroImage.file.url}
          alt={item.heroImage.id}
          component="img"
          title={item.heroImage.id}
          width={`${
            (item.heroImage.file.details.image.width /
              item.heroImage.file.details.image.height) *
            380
          }px`}
          height={`215px`}
        />
        <CardContent>
          <Typography variant="h6" style={{ fontFamily: "Poppins" }}>
            {item.title}
          </Typography>
          <div
            style={{ fontFamily: "Poppins" }}
            dangerouslySetInnerHTML={{
              __html: item.description.childMarkdownRemark.html,
            }}
          />

          <Typography variant="caption" style={{ fontFamily: "Poppins" }}>
            {item.publishDate}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default function Post({ pathContext, pageContext, location, data }) {
  const post = data?.filters?.edges[0]?.node;
  const other = data?.noFilters?.edges;
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  return (
    <React.Fragment>
      <RecoilRoot>
        <Helmet
          title={"MentorMe.md"}
          titleTemplate={`%s | mentorme.md`}
          meta={[
            {
              property: `og:title`,
              content: "[Blog] " + post.title,
            },
            {
              property: `og:description`,
              content: post.description.description,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              content: 'https:' + post.heroImage.file.url,
            },
          ]}
        />
        <DefaultLayout>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Breadcrumbs
                  separator={<NextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link to="/" className="heading_route">
                    {"Acasă"}
                  </Link>
                  <Link to="/blog" className="heading_route">
                    {"Blog"}
                  </Link>
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontStyle: "normal",
                      color: "#000",
                    }}
                  >
                    {post?.title}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={12}>
                <h1 className={sm ? "h1-desktop" : "h1-mobile"}>
                  {post?.title}
                </h1>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" className={classes.publishDate}>
                  {post?.publishDate}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {/**
                 * header blog post - !important elevation zero
                 */}
                <Card elevation={0}>
                  <CardHeader
                    avatar={<Avatar className={classes.avatar}>M</Avatar>}
                    action={
                      <>
                        <IconButton
                          className={classes.shareIcon}
                          target="_blank"
                          href={`https://twitter.com/intent/tweet?text=${encodeURI(
                            post?.description.description
                          )}&url=${encodeURI(
                            "https://mentorme.md" + location.pathname
                          )}&hashtag=mentorme`}
                        >
                          <TwitterIcon />
                        </IconButton>
                        <IconButton
                          target="_blank"
                          className={classes.shareIcon}
                          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                            "https://mentorme.md" + location.pathname
                          )}`}
                        >
                          <FacebookIcon />
                        </IconButton>
                        <IconButton
                          className={classes.shareIcon}
                          target="_blank"
                          href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
                            "https://mentorme.md" + location.pathname
                          )}&title=${encodeURI("my title")}&source=${encodeURI(
                            "https://mentorme.md/blog"
                          )}$summary=${encodeURI("Summary text")}`}
                        >
                          <LinkedInIcon />
                        </IconButton>
                      </>
                    }
                    className={classes.cardHeader}
                    title={post?.author?.name}
                    subheader={post?.author?.company}
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={0}>
                  <CardMedia
                    component="img"
                    title=""
                    src={post?.heroImage?.file.url}
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: post?.body?.childMarkdownRemark.html,
                  }}
                />
              </Grid>
              {other.length ? (
                <Grid item xs={12}>
                  <h3>{"Vezi și..."}</h3>
                </Grid>
              ) : null}
              {other?.map(({ node }) => (
                <Grid item xs={sm ? 4 : 12} key={node.id}>
                  <Item item={node} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </DefaultLayout>
      </RecoilRoot>
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $id: String!, $tags: [String!]!) {
    site {
      siteMetadata {
        title
      }
    }
    filters: allContentfulBlogPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          slug
          id
          author {
            name
            company
          }
          publishDate(formatString: "MMMM Do, YYYY")
          body {
            childMarkdownRemark {
              html
            }
          }
          description {
            description
          }
          tags
          heroImage {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            id
          }
        }
      }
    }
    noFilters: allContentfulBlogPost(
      limit: 3
      filter: { tags: { in: $tags }, id: { ne: $id } }
    ) {
      edges {
        node {
          tags
          title
          slug
          id
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            id
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
