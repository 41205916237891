import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    color: "#002E5D",
    fontWeight: "bold",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));
export default (props) => {
  const classes = useStyles();
  const { children, variant, centered, ...other } = props;
  return (
    <Typography variant={variant || "h2"} {...other} className={classes.title} style={centered ? { textAlign: "center" } : {}}>
      {children}
    </Typography>
  );
};
