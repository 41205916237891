import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    color: "#000",
  },
}));
export default (props) => {
  const classes = useStyles();
  const { children, variant, centered, ...other } = props;
  return (
    <Typography variant={variant || "h6"} {...other} className={classes.title} style={centered ? { textAlign: "center" } : {}}>
      {children}
    </Typography>
  );
};
