import React, { useState } from "react";
// import "normalize.css";
import "../../static/globalStyles/global.sass";

import DefaultLayout from "../layout/DefaultLayout";

import { Pagination, PaginationItem } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Breadcrumbs,
  // Paper,
  IconButton,
  // InputBase,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  FormControlLabel,
  FormGroup,
  Checkbox,
  useMediaQuery,
} from "@material-ui/core";
// import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { NavigateNext as NextIcon } from "@material-ui/icons/";

import { Link } from "gatsby";

import TitlePage from "../components/Typography/TitlePage";
// import Text from "../components/Typography/Text";

const useStyles = makeStyles((theme) => ({
  paginationRoot: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  itemMedia: {
    height: "100%",
  },
  item: {
    borderRadius: 12,
  },
  searchBar: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderRadius: 12,
  },
  inputSearch: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontFamily: "Poppins",
  },
  itemText: { fontFamily: "Poppins" },
  searchIcon: {
    padding: 10,
  },
  filterSelected: {
    backgroundColor: "#F2E154",
    textAlign: "center",
    color: "#002E5D",
    borderRadius: 14,
    fontFamily: "Poppins",
  },
  filter: {
    backgroundColor: "rgb(242, 225, 84,0.3)",
    textAlign: "center",
    color: "#002E5D",
    borderRadius: 14,
    fontFamily: "Poppins",
  },
  orderBy: {
    backgroundColor: "#1DA1A6",
    textAlign: "center",
    color: "#fff",
    borderRadius: 14,
    fontFamily: "Poppins",
  },
  filterLabel: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 6,
  },
  closeIcon: {
    color: "#002E5D",
  },
  closeButton: {
    borderRadius: 14,
    backgroundColor: "#1DA1A6",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1DA1A6",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FirstItem({ item }) {
  const classes = useStyles();
  return (
    <Card style={{ display: "flex" }} elevation={1}>
      <Grid item xs={5}>
        <CardActionArea href={`/blog/${item.slug}`}>
          <CardMedia
            src={item.heroImage.file.url}
            alt={item.heroImage.id}
            component="img"
          />
        </CardActionArea>
      </Grid>
      <Grid item xs={7}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CardContent style={{ flex: "1 0 auto" }}>
            <Typography variant="h5" className={classes.itemText}>
              {item.title}
            </Typography>
            <div
              style={{ fontFamily: "Poppins" }}
              dangerouslySetInnerHTML={{
                __html: item.description.childMarkdownRemark.html,
              }}
            />
            <Typography variant="caption" className={classes.itemText}>
              {item.publishDate}
            </Typography>
          </CardContent>
        </div>
      </Grid>
    </Card>
  );
}

function Item({ item }) {
  const classes = useStyles();
  return (
    <Card elevation={1} className={classes.item}>
      <CardActionArea href={`/blog/${item.slug}`}>
        <CardMedia
          src={item.heroImage.file.url}
          alt={item.heroImage.id}
          component="img"
          title={item.heroImage.id}
          width={`${
            (item.heroImage.file.details.image.width /
              item.heroImage.file.details.image.height) *
            380
          }px`}
          height={`215px`}
        />
        <CardContent>
          <Typography variant="h6" className={classes.itemText}>
            {item.title}
          </Typography>
          <div
            style={{ fontFamily: "Poppins" }}
            dangerouslySetInnerHTML={{
              __html: item.description.childMarkdownRemark.html,
            }}
          />

          <Typography variant="caption" className={classes.itemText}>
            {item.publishDate}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function Blog({ location, pageContext }) {
  const pathname = location.pathname;
  const classes = useStyles();
  const { currentPage, numPages, posts, limit } = pageContext;
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const [state, setState] = useState({
    inputSearch: "",
    openFilterDialog: false,
  });

  const handleCloseFilterDialog = () =>
    setState({ ...state, openFilterDialog: false });

  // const onInputSearchChange = (e) =>
  //   setState({ ...state, [e.target.name]: e.target.value });

  const handleSelectionChange = (e) =>
    setState((s) => {
      let { tags } = s;
      let selectionsUpdated = tags.map((s) => {
        if (s.label === e.target.name)
          return { ...s, checked: e.target.checked };
        return s;
      });
      return { ...s, tags: selectionsUpdated };
    });

  return (
    <DefaultLayout location={pathname}>
      <Dialog
        maxWidth="md"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        open={state.openFilterDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseFilterDialog}
        aria-labelledby="filter-dialog-slide-title"
        aria-describedby="filter-dialog-slide-description"
      >
        <DialogTitle id="filter-dialog-slide-title">
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <IconButton
                onClick={handleCloseFilterDialog}
                className={classes.closeIcon}
              >
                <HighlightOffIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <FormGroup row>
            {state.tags?.map((e) => (
              <FormControlLabel
                key={`form-control-label-key-${e}`}
                label={e}
                control={
                  <Checkbox
                    checked={false}
                    onChange={handleSelectionChange}
                    name={e}
                  />
                }
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions style={{ alignContent: "center" }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Button
                onClick={handleCloseFilterDialog}
                className={classes.closeButton}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs
              separator={<NextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link to="/" className="heading_route">
                {"Acasă"}
              </Link>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  color: "#000",
                }}
              >
                {"Blog"}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <TitlePage variant="h3">{"Bun venit pe blogul nostru!"}</TitlePage>
          </Grid>
          {/*
          START header description page
          <Grid item xs={12}>
            <Text variant="body1">
              Aici vom posta cele mai recente interviuri și articole ale
              comunității noastre.
            </Text>
          </Grid>
          END header description page
          */}
        </Grid>
        {/* 
        START search bar
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item xs={6}>
            <Paper component="form" className={classes.searchBar} elevation={3}>
              <InputBase
                key="input-search"
                id="input-search"
                name="inputSearch"
                value={state.inputSearch || ""}
                onChange={onInputSearchChange}
                fullWidth
                className={classes.inputSearch}
                placeholder="Search..."
                inputProps={{ "aria-label": "search articles" }}
              />
              <IconButton
                type="submit"
                aria-label="search"
                className={classes.searchIcon}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid> 
        END search bar
        */}

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={3}
        >
          {/* <Grid item xs={2}>
            <Card elevation={8} style={{ borderRadius: 16 }}>
              <CardActionArea className={classes.filterSelected}>
                <Typography className={classes.filterLabel}>Toate</Typography>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card elevation={8} style={{ borderRadius: 16 }}>
              <CardActionArea className={classes.filter}>
                <Typography className={classes.filterLabel}>
                  Interviuri
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card elevation={8} style={{ borderRadius: 16 }}>
              <CardActionArea className={classes.filter}>
                <Typography className={classes.filterLabel}>
                  Articole
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card elevation={8} style={{ borderRadius: 16 }}>
              <CardActionArea
                className={classes.orderBy}
                onClick={() => setState({ ...state, openFilterDialog: true })}
              >
                <Typography className={classes.filterLabel}>
                  Filtrează după
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
          */}
        </Grid>

        <Grid container spacing={3} alignItems="center">
          {/* <Grid item xs={12}> */}
          {/* <Grid container spacing={2}> */}
          {posts
            .slice((currentPage - 1) * limit, (currentPage - 1) * limit + limit)
            .map((e, i) => {
              // console.debug("element post:\n", e);
              if (i === 0 && pathname === "/blog" && sm) {
                return (
                  <Grid item xs={12} key={e.id}>
                    <FirstItem item={e} />
                  </Grid>
                );
              }
              return (
                <Grid item xs={sm ? 4 : 12} key={e.id}>
                  <Item item={e} />
                </Grid>
              );
            })}
          {/* </Grid> */}
          {/* </Grid> */}
          <Grid item xs={12}>
            <div className={classes.paginationRoot}>
              <Pagination
                count={numPages}
                size="large"
                disabled={!numPages}
                boundaryCount={2}
                // getItemAriaLabel={(type, page, selected) => {
                //   console.debug("getItemAriaLabel", type, page, selected);
                // }}
                // onChange={(event, page) =>
                //   setState({ ...state, currentPage: page })
                // }
                showFirstButton
                showLastButton
                page={currentPage}
                renderItem={(item) => (
                  <PaginationItem
                    component={Link}
                    to={`/blog${item.page === 1 ? "" : `/${item.page}`}`}
                    {...item}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  );
}

export default Blog;
